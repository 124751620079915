<template>
  <q-dialog v-model="open">
    <q-card>
      <q-card-section>
        <q-list>
          <q-item>
            <q-item-section>
              <q-item-label>Quantity</q-item-label>
              <q-item-label caption lines="2">
                The total number of that item. This multiplies net price
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label>Travel content type</q-item-label>
              <q-item-label caption lines="2">
                The travel type. We calculate the fee according to the setting for the selected option
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label>Unit price</q-item-label>
              <q-item-label caption lines="2">
                The amount we are charging the customer for one unit. <strong>Not including</strong> fees or taxes
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label>Tax rate</q-item-label>
              <q-item-label caption lines="2">
                What rate of VAT the item is subject to.<br>
                Zero: 0%, Standard: 20%, Zero, Reduced: 5%, Super Reduced: 0%, Parking: 0%
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label>Net</q-item-label>
              <q-item-label caption lines="2">
                Unit price multiplied by quantity
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label>Fee</q-item-label>
              <q-item-label caption lines="2">
                The generated fee according to the organisation pricing scheduled. <strong>Not including</strong> tax
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label>Tax</q-item-label>
              <q-item-label caption lines="2">
                The total tax due for this line. Includes tax for net price (if applicable) and tax for the fee
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    open: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style>

</style>
