<template>
  <tr>
    <td class="text-left">
      {{ line.quantity }}
    </td>
    <td class="text-left">
      {{ line.description }}
    </td>
    <td class="text-left">
      {{ unitPrice }}
    </td>
    <td class="text-left">
      {{ line.taxRate.label }}
    </td>
    <td class="text-left">
      {{ net }}
    </td>
    <td class="text-left">
      {{ fee }}
    </td>
    <td class="text-left">
      {{ tax }}
    </td>
  </tr>
</template>

<script>
import Money from 'js-money'
import { select, values as taxValues } from 'store/vat-rates'
export default {
  props: ['value', 'schedule', 'contentType'],
  data () {
    return {
      taxRates: select
    }
  },
  computed: {
    line () {
      return this.value
    },
    taxRate () {
      return this.line.taxRate ? taxValues[this.line.taxRate.value] : null
    },
    item () {
      return this.schedule[this.contentType]
    },
    unitPrice () {
      return new Money(this.line.unitPrice, Money.GBP)
    },
    net () {
      const net = new Money(this.line.unitPrice, Money.GBP)
      return net.multiply(this.line.quantity)
    },
    fee () {
      let fee = new Money(0, Money.GBP)

      if (this.item.percentage) {
        fee = this.net.multiply(this.item.percentage / 100)
      }

      if (this.item.min && (fee.amount < this.item.min.amount)) {
        fee = new Money(this.item.min.amount, Money.GBP)
      }

      if (this.item.max && fee.amount > this.item.max) {
        fee = new Money(this.item.max.amount, Money.GBP)
      }

      return fee
    },
    tax () {
      const unitPrice = new Money(this.line.unitPrice, Money.GBP)
      const itemTax = unitPrice.multiply(this.line.quantity).multiply(this.taxRate, Math.round)
      const feeTax = this.fee.multiply(0.2, Math.round)
      return itemTax.add(feeTax)
    }
  },
  watch: {
    tax: {
      immediate: true,
      handler (val) {
        this.line.tax = val
      }
    },
    fee: {
      immediate: true,
      handler (val) {
        this.line.fee = val
      }
    },
    net: {
      immediate: true,
      handler (val) {
        this.line.net = val
      }
    },
    line: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style>

</style>
