<template>
  <q-select
    v-model="type"
    v-bind="$attrs"
    :label="label || $t('content_type.title')"
    :options="translate(options)"
    :color="color || 'primary'"
    :dense="dense"
    map-options
    emit-value
    use-input
    hide-selected
    fill-input
    input-debounce="0"
    @filter="filterFn"
  >
    <template v-slot:append>
      <q-icon
        v-if="type"
        class="cursor-pointer"
        name="clear"
        @click.stop="type = null"
      />
    </template>
  </q-select>
</template>

<script>
const types = [
  {
    label: 'Car hire',
    value: 'rental',
    translation: ['content_type.rental', 1]
  },
  {
    label: 'Flights',
    value: 'flight',
    translation: ['content_type.flight', 2]
  },
  {
    label: 'Trains',
    value: 'rail',
    translation: ['content_type.train', 2]
  },
  {
    label: 'Taxi',
    value: 'ridehailing',
    translation: ['content_type.taxi', 1]
  },
  {
    label: 'Onward Travel',
    value: 'onwardtravel',
    translation: ['content_type.onwardtravel', 1]
  },
  {
    label: 'Hotel',
    value: 'hotel',
    translation: ['content_type.hotel', 1]
  },
  {
    label: 'Chauffeur',
    value: 'chauffeur',
    translation: ['chauffeur', 1]
  },
  {
    label: 'Eurostar',
    value: 'eurostar',
    translation: ['content_type.eurostar', 1]
  },
  {
    label: 'Airport Lounge',
    value: 'lounge',
    translation: ['airport_lounge', 1]
  },
  {
    label: 'Parking',
    value: 'parking',
    translation: ['parking', 1]
  },
  {
    label: 'DRT',
    value: 'drt',
    translation: ['content_type.drt', 1]
  },
  {
    label: 'Other',
    value: 'other',
    translation: ['other', 1]
  }
]

export default {
  props: {
    value: String,
    label: {
      type: String,
      default: ''
    },
    color: {
      type: String
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      options: types
    }
  },
  computed: {
    type: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    filterFn (val, update, abort) {
      update(() => {
        const needle = val.toLowerCase()
        this.options = types.filter(v => v.value.toLowerCase().indexOf(needle) > -1 || v.label.toLowerCase().indexOf(needle) > -1)
      })
    },
    translate (options) {
      return options.map(option => {
        const [ text, plural ] = option.translation
        return { ...option, label: this.$tc(text, plural) }
      })
    }
  }
}
</script>

<style>

</style>
