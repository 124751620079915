<template>
  <div class="bg-grey-2">
    <create-dialog
      v-model="open"
      @create="addQuote"
    />
    <help-dialog
      v-model="helpOpen"
    />
    <schedule-dialog
      v-model="scheduleOpen"
      :schedule="pricingSchedule"
    />
    <q-toolbar class="bar bg-black text-white shadow-2">
      <q-toolbar-title shrink>
        Quote builder
      </q-toolbar-title>
      <q-space />
      <q-btn
        icon="help"
        round
        dense
        flat
        @click="showHelpDialog"
      />
    </q-toolbar>
    <div class="container">
      <q-card>
        <q-card-section class="row justify-center">
          <q-btn-toggle
            v-model="customerType"
            toggle-color="primary"
            unelevated
            :options="[
              {label: 'Individual', value: 'individual'},
              {label: 'Organisation member', value: 'organisation'}
            ]"
          />
        </q-card-section>
        <q-card-section v-if="customerType === 'organisation'">
          <p class="text-grey text-center">
            Select an organisation to apply fees based on it's pricing schedule, otherwise it uses the default organisation schedule.
          </p>
          <organisation-lookup
            v-model="organisation"
            label="Organisation"
            autofocus
          />
        </q-card-section>
        <q-card-section v-else>
          <p class="text-grey text-center">
            Using a default individual pricing schedule to calculate fees
          </p>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            @click="showScheduleDialog"
          >
            Pricing Schedule
          </q-btn>
          <q-btn
            flat
            @click="openCreateQuoteDialog"
          >
            Add Quote
          </q-btn>
        </q-card-actions>
      </q-card>
      <div v-if="quotes.length">
        <quote
          v-for="(quote, i) in quotes"
          :key="quote.id"
          v-model="quotes[i]"
          :schedule="pricingSchedule"
          :index="i"
          @delete="deleteQuote"
        />
      </div>
      <div
        v-else
        class="empty-state-wrapper"
      >
        <m-empty-state
          :style="{padding: 0, margin: 'auto'}"
          :font-size="20"
          icon="business_center"
        >
          Add a quote
        </m-empty-state>
      </div>
    </div>
    <q-footer reveal elevated>
      <q-toolbar class="bg-black text-white">
        <div class="row full-width">
          <div class="col-sm-7" />
          <q-item class="col">
            <q-item-section>
              <q-item-label>
                Net
              </q-item-label>
              <q-item-label caption>
                {{ net }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item class="col">
            <q-item-section>
              <q-item-label>
                Fees
              </q-item-label>
              <q-item-label caption>
                {{ fees }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item class="col">
            <q-item-section>
              <q-item-label>
                Tax
              </q-item-label>
              <q-item-label caption>
                {{ tax }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item class="col">
            <q-item-section>
              <q-item-label>
                Total
              </q-item-label>
              <q-item-label caption>
                {{ total }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </div>
      </q-toolbar>
    </q-footer>
  </div>
</template>

<script>
import quote from './quote'
import createDialog from './create-dialog.vue'
import helpDialog from './help-dialog'
import organisationLookup from 'components/MFields/org-lookup.vue'
import { get as getOrganisation } from 'api/organisations'
import scheduleDialog from './schedule-dialog'
import Money from 'js-money'
import { sumBy } from 'lodash'
import { MEmptyState } from 'components/'

const individualSchedule = {
  rail: { min: { text: '£1.00', amount: 100 }, percentage: 5 },
  taxi: { min: { text: '£5.00', amount: 500 }, percentage: 0 },
  hotel: { min: { text: '£5.00', amount: 500 }, percentage: 0 },
  flight: { min: { text: '£10.00', amount: 1000 }, percentage: 0, uplift_percentage: 15 },
  lounge: { min: { text: '£5.00', amount: 500 }, percentage: 0 },
  rental: { min: { text: '£5.00', amount: 500 }, percentage: 0 },
  carclub: { min: { text: '£0.00', amount: 0 }, percentage: 0 },
  parking: { min: { text: '£5.00', amount: 500 }, percentage: 0 },
  eurostar: { min: { text: '£0.00', amount: 0 }, percentage: 0 },
  chauffeur: { min: { text: '£5.00', amount: 500 }, percentage: 0 },
  ridehailing: { min: { text: '£0.00', amount: 0 }, percentage: 5 }
}

const defaultOrgSchedule = {
  rail: { percentage: 5, min: { amount: 500, text: '£5.00' } },
  taxi: { percentage: 0, min: { amount: 1000, currency: 'GBP', text: '£10.00' } },
  hotel: { percentage: 0, min: { amount: 1000, currency: 'GBP', text: '£10.00' } },
  flight: { percentage: 0, min: { amount: 1500, currency: 'GBP', text: '£15.00' } },
  lounge: { percentage: 0, min: { amount: 1000, currency: 'GBP', text: '£10.00' } },
  rental: { percentage: 0, min: { amount: 1000, currency: 'GBP', text: '£10.00' } },
  carclub: { percentage: 0, min: { amount: 0, currency: 'GBP', text: '£0.00' } },
  parking: { percentage: 0, min: { amount: 1000, currency: 'GBP', text: '£10.00' } },
  eurostar: { percentage: 0, min: { amount: 0, currency: 'GBP', text: '£0.00' } },
  chauffeur: { percentage: 0, min: { amount: 1000, currency: 'GBP', text: '£10.00' } },
  ridehailing: { percentage: 5, min: { amount: 0, currency: 'GBP', text: '£0.00' } }
}

export default {
  components: { createDialog, quote, helpDialog, organisationLookup, scheduleDialog, MEmptyState },
  data () {
    return {
      customerType: 'individual',
      helpOpen: false,
      scheduleOpen: false,
      open: false,
      organisation: null,
      pricingSchedule: individualSchedule,
      quotes: []
    }
  },
  computed: {
    net () {
      return Money.fromInteger(
        sumBy(this.quotes, q => sumBy(q.lines, line => line.net ? line.net.amount : 0)),
        Money['GBP']
      )
    },
    fees () {
      return Money.fromInteger(
        sumBy(this.quotes, q => sumBy(q.lines, line => line.fee ? line.fee.amount : 0)),
        Money['GBP']
      )
    },
    tax () {
      return Money.fromInteger(
        sumBy(this.quotes, q => sumBy(q.lines, line => line.tax ? line.tax.amount : 0)),
        Money['GBP']
      )
    },
    total () {
      return this.net.add(this.fees).add(this.tax)
    }
  },
  watch: {
    customerType (val) {
      if (val === 'individual') {
        this.pricingSchedule = individualSchedule
        this.organisation = null
      } else {
        this.pricingSchedule = defaultOrgSchedule
      }
    },
    organisation (val) {
      if (!val) {
        this.pricingSchedule = individualSchedule
        return
      }
      getOrganisation(val.value, {
        include: 'pricingschedule'
      })
        .then((res) => {
          this.pricingSchedule = res.data.pricingschedule.data
        })
    }
  },
  created () {
    window.addEventListener('keyup', this.openWithPlus)
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.openWithPlus)
  },
  methods: {
    openCreateQuoteDialog () {
      this.open = true
    },
    addQuote (quote) {
      this.quotes.push(quote)
    },
    deleteQuote (index) {
      const updated = [...this.quotes]
      updated.splice(index, 1)
      this.quotes = updated
    },
    openWithPlus (e) {
      if (e.key === '=' && !e.shiftKey && !e.ctrlKey) {
        this.openCreateQuoteDialog()
      }
    },
    showHelpDialog () {
      this.helpOpen = true
    },
    showScheduleDialog () {
      this.scheduleOpen = true
    }
  }
}
</script>

<style lang="stylus" scoped>
.empty-state-wrapper
  height 300px
  display grid

.bar
  position relative
  z-index 10

.container
  max-width 1024px
  margin auto
  min-height calc(100vh - 128px)
</style>
