<template>
  <q-card class="quote">
    <q-card-section class="lines">
      <div class="row">
        <div class="text-h6 col text-capitalize">
          {{ quote.contentType }}
        </div>
        <div class="col-sm-1 flex justify-end">
          <q-btn
            icon="close"
            round
            dense
            flat
            class="bg-grey-2"
            @click="$emit('delete', index)"
          />
        </div>
      </div>
      <line-entry
        :schedule="schedule"
        :content-type="quote.contentType"
        @submit="addLine"
      />
      <q-markup-table flat>
        <thead>
          <tr>
            <th class="text-left">
              Quantity
            </th>
            <th class="text-left">
              Description
            </th>
            <th class="text-left">
              Unit price
            </th>
            <th class="text-left">
              Tax rate
            </th>
            <th class="text-left">
              Net
            </th>
            <th class="text-left">
              Fee
            </th>
            <th class="text-left">
              Tax
            </th>
          </tr>
        </thead>
        <tbody>
          <table-row
            v-for="(line, i) in quote.lines"
            :key="i"
            v-model="quote.lines[i]"
            :schedule="schedule"
            :content-type="quote.contentType"
          />
        </tbody>
      </q-markup-table>
      <q-separator />
      <div class="row">
        <div class="col-sm-7" />
        <q-item class="col">
          <q-item-section>
            <q-item-label>
              Net
            </q-item-label>
            <q-item-label caption>
              {{ net }}
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item class="col">
          <q-item-section>
            <q-item-label>
              Fees
            </q-item-label>
            <q-item-label caption>
              {{ fees }}
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item class="col">
          <q-item-section>
            <q-item-label>
              Tax
            </q-item-label>
            <q-item-label caption>
              {{ tax }}
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item class="col">
          <q-item-section>
            <q-item-label>
              Total
            </q-item-label>
            <q-item-label caption>
              {{ total }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import Money from 'js-money'
import { sumBy } from 'lodash'
import lineEntry from './line-entry'
import tableRow from './table-row'
export default {
  components: { lineEntry, tableRow },
  props: ['value', 'schedule', 'index'],
  data () {
    return {
      scheduleOpen: false
    }
  },
  computed: {
    quote: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    net () {
      return Money.fromInteger(
        sumBy(this.quote.lines, line => line.net ? line.net.amount : 0),
        Money['GBP']
      )
    },
    fees () {
      return Money.fromInteger(
        sumBy(this.quote.lines, line => line.fee ? line.fee.amount : 0),
        Money['GBP']
      )
    },
    tax () {
      return Money.fromInteger(
        sumBy(this.quote.lines, line => line.tax ? line.tax.amount : 0),
        Money['GBP']
      )
    },
    total () {
      return this.net.add(this.fees).add(this.tax)
    }
  },
  created () {
    window.addEventListener('keyup', this.addLineWithCtrlPlus)
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.addLineWithCtrlPlus)
  },
  methods: {
    addLine (line) {
      const updated = [
        ...this.quote.lines,
        {
          ...line,
          id: Math.random()
        }
      ]

      this.quote = {
        ...this.quote,
        lines: updated
      }
    },
    deleteLine (index) {
      const updated = [...this.quote.lines]
      updated.splice(index, 1)
      this.quote = {
        ...this.quote,
        lines: updated
      }
    },
    toPounds (int) {
      return Money.fromInteger(
        int,
        Money['GBP']
      ).toString()
    },
    addLineWithCtrlPlus (e) {
      if (e.key === '+') {
        this.addLine()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.row
  width 100%
.entry-line, .lines
  padding 16px
</style>
