<template>
  <m-select-query
    v-model="org"
    v-bind="$attrs "
    :label="$tc('organisation.organisation')"
    :query="autocomplete"
    clearable
  />
</template>

<script>
import { index } from 'api/organisations'
import { MSelectQuery } from 'components/'

export default {
  components: { MSelectQuery },
  props: ['color', 'label', 'value', 'icon', 'roles', 'dense'],
  computed: {
    org: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    autocomplete (terms) {
      const params = { search: terms }
      return index({ params })
        .then(response => {
          return response.data.map(org => {
            return {
              label: org.name,
              value: org.slug,
              icon: 'group',
              color: 'orange-9',
              description: org.slug
            }
          })
        })
    }
  }
}
</script>

<style>

</style>
