<template>
  <div class="row q-col-gutter-sm">
    <div class="col-1">
      <q-input
        v-model="line.quantity"
        label="Quantity"
        type="number"
        stack-label
        :min="1"
        dense
      />
    </div>
    <div class="col-3">
      <q-input
        v-model="line.description"
        label="Description"
        stack-label
        dense
      />
    </div>
    <div class="col">
      <money-field
        v-model="line.unitPrice"
        label="Unit price"
        dense
      />
    </div>
    <div class="col">
      <q-select
        v-model="line.taxRate"
        :options="taxRates"
        label="Tax Rate"
        map-options
        stack-label
        dense
      />
    </div>
    <q-btn
      label="add"
      class="bg-grey-2 padding"
      dense
      flat
      @click="submit"
    />
  </div>
</template>

<script>
import { select } from 'store/vat-rates'
import moneyField from 'components/MFields/money.vue'

const line = {
  quantity: 1,
  unitPrice: null,
  taxRate: null,
  net: null,
  tax: null,
  fee: null
}
export default {
  components: { moneyField },
  props: ['schedule', 'contentType'],
  data () {
    return {
      taxRates: select,
      line: { ...line }
    }
  },
  computed: {
    lineValid () {
      return this.line.unitPrice !== null && this.line.taxRate !== null
    }
  },
  methods: {
    submit () {
      if (this.lineValid) {
        this.$emit('submit', this.line)
        this.line = { ...line }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.padding
  width 70px
  height 40px
  margin-top 20px

.row
  margin-top 16px
  width 100%
  background #fefefe

</style>
