import _ from 'lodash'
import i18n from 'i18n'

export const select = [
  {
    label: i18n.t('tax.zero_rate'),
    value: 'zero'
  },
  {
    label: i18n.t('tax.standard_rate'),
    value: 'standard'
  },
  {
    label: i18n.t('tax.reduced_rate'),
    value: 'reduced'
  },
  {
    label: i18n.t('tax.super_reduced_rate'),
    value: 'superreduced'
  },
  {
    label: i18n.t('tax.parking_rate'),
    value: 'parking'
  },
  {
    label: i18n.t('tax.hospitality'),
    value: 'hospitality'
  }
]

export const selectIndex = _.keyBy(select, 'value')

export const values = {
  zero: 0,
  standard: 0.2,
  reduced: 0.05,
  superreduced: 0,
  parking: 0,
  hospitality: 0.2
}
