<template>
  <q-dialog v-model="open">
    <q-card class="card">
      <q-table
        title="Pricing Schedule"
        :data="list"
        :columns="columns"
        :pagination="{ rowsPerPage: 0 }"
        row-key="content_type"
        hide-bottom
      />
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ['value', 'schedule'],
  data () {
    return {
      columns: [
        { label: 'Content Type', field: 'content_type' },
        { label: 'Percentage', field: 'percentage' },
        { label: 'Min', field: row => row.min ? row.min.text : null },
        { label: 'Max', field: row => row.max ? row.max.text : null }
      ]
    }
  },
  computed: {
    list () {
      return Object.keys(this.schedule).map(k => {
        return {
          ...this.schedule[k],
          content_type: k
        }
      })
    },
    open: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style>

</style>
