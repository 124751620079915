<template>
  <q-dialog v-model="open" persistent>
    <q-card class="card">
      <form @submit="createQuote">
        <q-card-section>
          <div class="text-h6">
            Add new quote
          </div>
        </q-card-section>
        <q-card-section>
          <content-type-select
            v-model="quote.contentType"
            label="Travel content type"
          />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn v-close-popup flat label="Cancel" color="mobilleo" />
          <q-btn flat label="Create" color="mobilleo" type="submit" />
        </q-card-actions>
      </form>
    </q-card>
  </q-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import contentTypeSelect from 'components/MFields/content-type-select.vue'

function quoteModel () {
  return {
    contentType: null,
    lines: []
  }
}

export default {
  components: { contentTypeSelect },
  props: ['value'],
  data () {
    return {
      quote: quoteModel()
    }
  },
  computed: {
    open: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    createQuote (e) {
      e.preventDefault()
      this.$v.$touch()
      if (this.$v.$error) return
      this.$emit('create', {
        ...this.quote
      })
      this.quote = quoteModel()
      this.open = false
    }
  },
  validations: {
    quote: {
      contentType: { required }
    }
  }
}
</script>

<style lang="stylus" scoped>
.card
  width 100vw
  max-width 400px

</style>
